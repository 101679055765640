import { Avatar, Badge, Box, BoxProps, Button, chakra, Container, Flex, Heading, HStack, Icon, Image, Input, SimpleGrid, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { type NextPage } from "next";
import { AuthAction, withAuthUser } from "next-firebase-auth";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import { AE } from "../@types/analytics";
import { EAnimation } from "../@types/global_types";
import LottiePlayer from "../components/LottiePlayer";
import MetaTags from "../components/MetaTags";
import _c from "../configs/constants";
import Analytics from "../controllers/analytics_controller";
import { compactNotation } from "../utils/number_util";
import { IGetServerSidePropsContext, withAppContext } from "../utils/ssr_util";
interface Feature {
  id: string;
  title: string;
  description: string;
  image: string;
}
const LIVELINK_FEATURES: Feature[] = [{
  id: "feature_1",
  title: "Community platform",
  description: "Build your community so you can start creating a buzz right away.",
  image: "/images/home/community.svg"
}, {
  id: "feature_3",
  title: "No monthly fees",
  description: "We only make money when you do, with a simple 15% fee applied to all payments.",
  image: "/images/home/fees.svg"
}, {
  id: "feature_5",
  title: "Payments",
  description: "Multiple currency handling and instant pay outs on your creator subscription.",
  image: "/images/home/payments.svg"
}, {
  id: "feature_2",
  title: "Invites and notifications",
  description: "Automated marketing emails, calendar invites, and scheduling so your subscribers never miss a session.",
  image: "/images/home/invites.svg"
}, {
  id: "feature_4",
  title: "Support",
  description: "Your dedicated LiveLink contact is always on hand to help with all the logistics.",
  image: "/images/home/support.svg"
}, {
  id: "feature_6",
  title: "Analytics",
  description: "Your unique dashboard shows income, key metrics from marketing automations, and how your community is interacting with you.",
  image: "/images/home/analytics.svg"
}];
const FeatureBlock = ({
  feature
}: {
  feature: Feature;
}) => {
  return <Box backgroundColor="white" borderRadius="24px" p={8} display="inline-block" w="100%" data-sentry-element="Box" data-sentry-component="FeatureBlock" data-sentry-source-file="index.tsx">
            <VStack spacing={3} alignItems="flex-start" data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                <Image width="60px" height="60px" src={feature.image} alt={feature.title} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                <Heading fontSize="24px" data-sentry-element="Heading" data-sentry-source-file="index.tsx">{feature.title}</Heading>
                <Text fontSize="16px" color="blackAlpha.500" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                    {feature.description}
                </Text>
            </VStack>
        </Box>;
};
interface TimeSavedPillProps extends BoxProps {
  text: string;
}
const TimeSavedPill = ({
  text,
  ...rest
}: TimeSavedPillProps) => {
  return <Flex alignItems="center" backgroundColor="#FAEDE3" borderRadius="20px" paddingX="14px" paddingY="4px" width="auto" fontWeight="medium" border="1px solid white" {...rest} data-sentry-element="Flex" data-sentry-component="TimeSavedPill" data-sentry-source-file="index.tsx">
            <Icon as={MdOutlineAccessTime} mr={2} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
            {text}
        </Flex>;
};
const HighlightedText = ({
  children
}: any) => {
  return <Text display="inline-block" color="#62D3A3" data-sentry-element="Text" data-sentry-component="HighlightedText" data-sentry-source-file="index.tsx">
            {children}
        </Text>;
};
const IndexPage: NextPage = () => {
  const router = useRouter();
  const joinInputRef = useRef<HTMLInputElement | null>(null);
  const [username, setUsername] = useState("");
  const [hasFocusedInput, setHasFocusedInput] = useState(false);
  const gradientAreaHeight = useBreakpointValue({
    base: "120px",
    lg: "200px"
  });
  useEffect(() => {
    // Focuses the input at the end of the homepage once it comes into view
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !hasFocusedInput) {
        setHasFocusedInput(true);
        setTimeout(() => {
          joinInputRef.current?.focus();
        }, 300);
      }
    });
    joinInputRef.current && observer.observe(joinInputRef.current);
    return () => {
      if (joinInputRef.current) {
        observer.unobserve(joinInputRef.current);
      }
    };
  }, [joinInputRef, hasFocusedInput]);
  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    router.push(`/signup?username=${encodeURIComponent(username)}`);
    Analytics.trackEvent(AE.Signup_SubmitClaimUsername, {
      username
    });
  };
  return <>
            <MetaTags title="Setup your creator membership, host paid Livestreams, build a community and automate your marketing." data-sentry-element="MetaTags" data-sentry-source-file="index.tsx" />
            <Flex pos="relative" flexDir="column" width="100vw" backgroundColor="#F7F7F7" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                <Box height={[null, null, "70vh"]} position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box as="video" autoPlay={true} loop={true} muted={true} position="absolute" top="0px" left="0px" height="100%" width="100%" objectFit="cover" playsInline={true} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                        <source src="/images/hero.webm" type="video/webm" />
                        <source src="/images/hero.mp4" type="video/mp4" />
                    </Box>
                    <Box position="absolute" top="0" right="0" left="0" bottom="0" width="100%" height="100%" background={["linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.9) 70%, rgba(0,0,0,1) 100%)", null, "transparent linear-gradient(90deg, #000000 0%, #00000000 100%) 0% 0% no-repeat"]} opacity={0.75} data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                    <Container paddingX="20px" maxW="container.xl" position="relative" height="100%" display="flex" alignItems="center" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Flex direction="column" alignItems="flex-start" width={["100%", null, "50%"]} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                            <Heading color="white" fontSize={["36px", null, "50px"]} marginTop={["300px", null, "0px"]} marginBottom="12px" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                We do it <HighlightedText data-sentry-element="HighlightedText" data-sentry-source-file="index.tsx">all</HighlightedText> for you
                            </Heading>
                            <Text color="white" fontSize="20px" lineHeight={1.2} fontWeight={500} padding={0} marginBottom={["12px", null]} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Bring your community together with live streams, subscriptions,
                                recordings, and automated marketing campaigns.
                            </Text>
                            <chakra.form onSubmit={handleSignup} display={"contents"} width={["100%", null, "auto"]} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                                <Flex direction={["column", null, "row"]} alignItems={["flex-start", null, "center"]} borderWidth={2} borderColor="black" borderRadius={["xl", null, "2xl"]} overflow="hidden" backgroundColor="white" width={["100%", null, "auto"]} height={[null, null, "64px"]} marginBottom="30px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                                    <Flex direction="row" justifyContent="flex-start" alignItems="center" height="50px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                                        <Text paddingLeft={4} fontSize="20px" fontWeight="medium" letterSpacing={0.2} marginBottom={0} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                            livelink.vip/
                                        </Text>
                                        <Input placeholder="yourname" backgroundColor="white" fontSize="20px" fontWeight="medium" letterSpacing={0.2} border={0} px={2} width={[null, null, 150]} flexGrow={1} value={username} onChange={e => setUsername(e.currentTarget.value?.trim())} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
                                    </Flex>
                                    <Button type="submit" variant="primary" backgroundColor="#06D6A0" height={["50px", null, "64px"]} borderRadius={0} px={4} fontSize={["16px", null, "20px"]} borderTop={["2px solid black", null, "none"]} borderLeft={[null, null, "2px solid black"]} width={["100%", null, "auto"]} letterSpacing={0.2} boxShadow="none" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                        Claim your username
                                    </Button>
                                </Flex>
                            </chakra.form>
                        </Flex>
                    </Container>
                </Box>

                <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box position="absolute" backgroundColor="#fdefe6" height={["645px", "630px", "550px", "680px"]} width={["80%", null, "50%"]} top={["25px", 0]} right="0px" borderBottomLeftRadius={"48px"} borderTopLeftRadius={["48px", 0]} data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                    <Container paddingX="20px" display="flex" flexDirection="column" maxW="container.xl" position="relative" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Image src="/images/home/creator-laptop.png" alt="Creator on laptop screen teaching using LiveLink" position="absolute" width={["130%", "80%", "400px", "813px"]} top={["65px", null, "80px"]} right={["-15%", "10%", "40px"]} maxWidth={["none"]} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <Image src="/images/home/event-card.png" alt="Event on LiveLink" position="absolute" width={["155px", null, "190px", "290px"]} top={["46px", null, "170px"]} right={["0px", "12%", "10px"]} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <VStack alignItems={["flex-start", "center", "flex-start"]} maxW={[null, null, "400px"]} spacing={5} pt={["100vw", "60vw", "140px"]} pb={["45px", null, "180px"]} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                            <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" textAlign={["left", "center", "left"]} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                Live streams, guest invites, and payments
                                <br />
                                <HighlightedText data-sentry-element="HighlightedText" data-sentry-source-file="index.tsx">We do it for you</HighlightedText>
                            </Heading>
                            <Text fontSize="14px" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Easily host paid live streams for your LiveLink community. It’s all
                                on your terms.
                            </Text>
                            <NextLink href="/signup" passHref legacyBehavior data-sentry-element="NextLink" data-sentry-source-file="index.tsx">
                                <Button as="a" size="lg" variant="primary" width={["100%", "30%", "auto"]} boxShadow="0px 9px 20px 0px #0000004D" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    Get Started
                                </Button>
                            </NextLink>
                            <TimeSavedPill text="Save 25 hours p/m" position={["static", null, "absolute"]} margin-top={["30px", 0]} top={[null, null, "450px", "130px"]} left={[null, null, "40%", "420px"]} data-sentry-element="TimeSavedPill" data-sentry-source-file="index.tsx" />
                        </VStack>
                    </Container>
                </Box>

                <Box position="relative" _before={{
        content: '""',
        display: "block",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: gradientAreaHeight,
        background: "linear-gradient(0deg, transparent, #F7F7F7)",
        zIndex: 1
      }} _after={{
        content: '""',
        display: "block",
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        height: gradientAreaHeight,
        background: "linear-gradient(0deg, #F7F7F7, transparent)",
        zIndex: 1
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box backgroundImage="/images/home/grid-bg.svg" backgroundRepeat="repeat" backgroundSize="1165px" position="absolute" width="100%" height="100%" top="0px" left="0px" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                    <Container paddingX="20px" display="flex" flexDirection="column" maxW="container.xl" position="relative" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Image src="/images/home/earnings-graph.svg" display={["none", null, "block"]} width="840px" position="absolute" top={[null, null, "255px", "400px"]} left={[null, null, "-40px", "-50px"]} alt="Graph of increasing earnings over time" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <Image src="/images/home/behaviour-flow.svg" width={["210px", "240px", "264px"]} position="absolute" top={["380px", "355px", "130px", "280px"]} right={["-8px", "20%", "30px", "150px"]} alt="Diagram of LiveLink getting users to purchase more of your products" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <Image src="/images/home/mailing-list.svg" position="absolute" display={["block", null, "none", "block"]} width={["425px", "470px", "750px"]} top={["295px", "255px", "660px"]} right={["-50px", "15%", "50px"]} maxW="none" alt="Option for users to subscribe to your mailing list" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <Image src="/images/home/ad-views.svg" position="absolute" display={["block", null, "none", "block"]} width={["195px", null, "330px"]} top={["335px", "330px", "450px"]} left={["0px", "12%", "650px"]} alt="Graph of ads run for your content" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <TimeSavedPill text="Save 25 hours p/m" position="absolute" top={["526px", null, "230px", "330px"]} left={["8px", "15%", "300px", "500px"]} data-sentry-element="TimeSavedPill" data-sentry-source-file="index.tsx" />
                        <VStack alignItems={["flex-start", "center", "flex-start"]} maxW={["none", null, "580px"]} spacing={5} pt={["70px", null, "0px", "140px"]} pb={["370px", null, "650px"]} zIndex={1} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                            <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" textAlign={["left", "center", "left"]} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                Automated marketing, retargeting and analytics
                                <br />
                                <HighlightedText data-sentry-element="HighlightedText" data-sentry-source-file="index.tsx">We handle that too</HighlightedText>
                            </Heading>
                            <Text fontSize="14px" maxW="300px" textAlign={["left", "center", "left"]} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Tired of writing emails, chasing checkout drop-offs and maintaining
                                nurture flows? We do your marketing automations.
                            </Text>
                        </VStack>
                    </Container>
                </Box>

                <Container paddingX="20px" maxW="container.xl" position="relative" display="flex" alignItems="flex-start" justifyContent="center" overflow="hidden" backgroundColor="black" borderRadius={[0, null, null, "38px"]} marginBottom={20} backgroundImage="/images/home/video-editor.jpg" backgroundSize={["auto 100%", null, null, "cover"]} backgroundPosition={["70%", null, null, "none"]} backgroundRepeat="no-repeat" zIndex={1} data-sentry-element="Container" data-sentry-source-file="index.tsx">
                    <Image src="/images/home/vignette-gradient-overlay.svg" position="absolute" width={["auto", null, null, "110%"]} height={["100%", null, null, "auto"]} top="0px" left={["-10%", null, null, "-5%"]} maxW="none" alt="gradient" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    <Flex w="100%" direction="column" alignItems="center" position="relative" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                        <TimeSavedPill text="Save 25 hours p/m" display={["none", null, "display"]} position="absolute" bottom="50px" right="50px" data-sentry-element="TimeSavedPill" data-sentry-source-file="index.tsx" />
                        <VStack spacing={[8, null, null, 5]} maxW="885px" justifyContent="center" textAlign="center" mt={10} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                            <LottiePlayer animation={EAnimation.broadcastDot} loop autoplay style={{
              width: "125px",
              height: "125px"
            }} data-sentry-element="LottiePlayer" data-sentry-source-file="index.tsx" />
                            <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" textAlign="center" color="white" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                Record, edit & sell your live streams
                                <br />
                                <HighlightedText data-sentry-element="HighlightedText" data-sentry-source-file="index.tsx">{`We've got that covered`}</HighlightedText>
                            </Heading>
                            <Text color="white" maxW="370px" textShadow="0 0 10px black" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Sit back and relax. Our automation records your live sessions and we
                                edit them for you. It’ll be ready for your community to purchase
                                after each session.
                            </Text>
                        </VStack>
                        <Box position="relative" width={["285px", null, null, "350px"]} height={["305px", null, null, "381px"]} marginTop="45px" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                            <Image src="/images/home/creator-payment-list.svg" alt="List of payments sent to creators" width="350px" position="absolute" top="0px" left="0px" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                            <Box background={["linear-gradient(0deg, rgba(0,0,0,0.8), transparent)", null, "linear-gradient(0deg, black, transparent)"]} width="100%" height="200px" position="absolute" bottom={0} left="0px" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                        </Box>
                    </Flex>
                </Container>

                <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Container maxW="container.xl" display="flex" flexDirection="column" position="relative" paddingX="20px" paddingY="125px" zIndex={1} data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <VStack alignItems={{
            base: "flex-start",
            md: "center"
          }} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                            <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                Community templates, channels and chat
                            </Heading>
                            <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" mb={10} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                <HighlightedText data-sentry-element="HighlightedText" data-sentry-source-file="index.tsx">We do it for you</HighlightedText>
                            </Heading>
                            <Text fontSize="14px" maxW="450px" textAlign="center" py={15} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Effortlessly connect with your community using our pre-built
                                templates, channels, and chat functionality.
                            </Text>
                        </VStack>
                        <Image src="/images/home/community-feature.png" alt="Livelink Community" top="0" right="0" left="0" bottom="0" width="100%" height="100%" objectFit="cover" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    </Container>
                </Box>
                <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Container display="flex" alignItems="center" position="relative" maxW="container.xl" paddingX="20px" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Box backgroundColor="#fdefe6" width="450px" height="450px" position="absolute" filter="auto" blur="50px" right="-50px" top="0px" borderRadius="50%" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                        <Box backgroundColor="#fdefe6" width="700px" height="700px" position="absolute" filter="auto" blur="50px" left="-150px" top="50px" borderRadius="50%" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                        <VStack alignItems={["flex-start", "center", "flex-start"]} maxW={[null, null, "530px"]} spacing={5} marginTop={["340px", null, "140px"]} marginBottom={["250px", null, "350px"]} zIndex={1} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                            <Box backgroundColor="white" color="black" border="2px solid black" borderRadius="11px" fontSize="13px" fontWeight="semibold" padding="5px 12px" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                                BRAND NEW
                            </Box>
                            <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" textAlign={["left", "center", "left"]} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                Subscriptions, support & automatic reminders
                                <br />
                                <HighlightedText data-sentry-element="HighlightedText" data-sentry-source-file="index.tsx">Leave that to us</HighlightedText>
                            </Heading>
                            <Text fontSize="14px" maxW="300px" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                Earn recurring revenue from subscriptions, or choose one-off fixed
                                prices. You’re in full control.
                            </Text>
                            <NextLink href="/signup" passHref legacyBehavior data-sentry-element="NextLink" data-sentry-source-file="index.tsx">
                                <Button id="home-signup-link" as="a" size="lg" variant="primary" boxShadow="0px 9px 20px 0px #0000004D" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    Get Started
                                </Button>
                            </NextLink>
                        </VStack>
                        <Image src="/images/home/creator-mobile.png" alt="Creator teaching on mobile" width={["375px", null, "650px", "743px"]} position="absolute" top={["0px", null, "160px", "70px"]} right={["0px", "calc((100% - 375px) / 2)", "-165px", "120px"]} maxWidth="none" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <Image src="/images/home/subscription-creation.png" alt="Followers paying for subscriptions" width={["210px", null, "250px", "405px"]} position="absolute" top={["-15px", null, "165px", "50px"]} right={["0px", "15%", "30px", "70px"]} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        <TimeSavedPill text="Save 10 hours p/m" position="absolute" top={["700px", null, "448px", "630px"]} right={["unset", "calc((100% - 172px) / 2)", "202px", "230px"]} left={["20px", "unset"]} data-sentry-element="TimeSavedPill" data-sentry-source-file="index.tsx" />
                    </Container>
                </Box>

                <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box backgroundColor="#ffffff" border="1px solid #F9EFE7" borderRadius="0 27px 27px 0" width={["100%", null, "62%"]} height={["600px", null, "450px"]} position="absolute" top="0px" left="0px" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                    <Container paddingX="20px" maxW="container.xl" position="relative" alignItems="center" display="flex" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <VStack alignItems="flex-start" mt="70px" pb={["380px", null, "140px", "250px"]} maxW="500px" spacing={5} data-sentry-element="VStack" data-sentry-source-file="index.tsx">
                            <Avatar src="/images/home/creator-avatar.jpeg" name="Adam" width="110px" height="110px" borderWidth="10px" position="absolute" top="-60px" left="0px" data-sentry-element="Avatar" data-sentry-source-file="index.tsx" />
                            <Heading fontSize={["40px", null, "44px"]} lineHeight="1" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                Adam Gordon
                            </Heading>
                            <HStack data-sentry-element="HStack" data-sentry-source-file="index.tsx">
                                <Badge variant="creatorOffering" data-sentry-element="Badge" data-sentry-source-file="index.tsx">weekly lives</Badge>
                                <Badge variant="creatorOffering" data-sentry-element="Badge" data-sentry-source-file="index.tsx">3+ recordings</Badge>
                                <Badge variant="creatorOffering" data-sentry-element="Badge" data-sentry-source-file="index.tsx">$500+ p/m</Badge>
                            </HStack>
                            <HStack spacing={4} data-sentry-element="HStack" data-sentry-source-file="index.tsx">
                                <HStack spacing={2} data-sentry-element="HStack" data-sentry-source-file="index.tsx">
                                    <Image src="/images/home/tiktok-coloured-logo.svg" alt="TikTok colored logo" height="20px" display="inline-block" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    <Text color="blackAlpha.700" fontWeight="medium" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                        {compactNotation(52_000)}+ followers
                                    </Text>
                                </HStack>
                            </HStack>
                            <Text fontSize="14px" maxWidth={[null, null, "420px", "auto"]} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                                &ldquo;LiveLink has been great for allowing me to start my journey
                                of educating others about photography. Their platform is very easy
                                to use (for both the teacher and student) and is very simplistic
                                with collecting payment and sending out all necessary emails before
                                the Live. This allows me to fully focus on the content I want to
                                teach my students!&rdquo;
                            </Text>
                            <NextLink href="/creators" passHref legacyBehavior data-sentry-element="NextLink" data-sentry-source-file="index.tsx">
                                <Button id="meet-creators" as="a" size="lg" variant="primary" boxShadow="0px 9px 20px 0px #0000004D" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    Meet our creators
                                </Button>
                            </NextLink>
                        </VStack>
                        <Box backgroundColor="#fdefe6" position="absolute" filter="auto" blur="50px" borderRadius="50%" opacity="0.7" width={["90%", null, "300px", "500px"]} height={["240px", null, "200px", "500px"]} top={["480px", "400px", "120px", "0px"]} right={["5%", null, "0px"]} data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                        <chakra.video src="/videos/home/creator-live-session.mp4" controls={false} autoPlay muted loop position="absolute" width={["85%", "75%", "40vw", "480px", "560px"]} right={["7.5%", "12.5%", "25px", "60px", "130px"]} top={["425px", "370px", "140px", "70px"]} height="auto" backgroundColor="gray.200" borderRadius="24px" boxShadow="0px 9px 40px 0px #0000001A" border="4px solid black" data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                    </Container>
                </Box>

                <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box backgroundColor="#FAEDE3" width="100%" height="200px" position="absolute" bottom={0} left="0px" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                    <Container maxW="container.xl" display="flex" flexDirection="column" position="relative" paddingX="20px" paddingBottom="50px" zIndex={1} data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Heading fontSize={["32px", "38px", "50px"]} lineHeight="1" mb={10} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                            {`There's more.`}
                        </Heading>
                        <SimpleGrid columns={[1, 2, 3]} spacing={5} data-sentry-element="SimpleGrid" data-sentry-source-file="index.tsx">
                            {LIVELINK_FEATURES.map(feature => {
              return <FeatureBlock key={feature.id} feature={feature} />;
            })}
                        </SimpleGrid>
                        <TimeSavedPill text="Save 50 hours p/m" marginTop="50px" alignSelf="center" data-sentry-element="TimeSavedPill" data-sentry-source-file="index.tsx" />
                    </Container>
                </Box>

                <Box position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box width="100%" height="100%" background="linear-gradient(to right top, #ffffff 50%, #FAEDE3 50.3%)" position="absolute" data-sentry-element="Box" data-sentry-source-file="index.tsx" />
                    <Container paddingX="20px" maxW="container.xl" position="relative" alignItems="center" justifyContent="center" display="flex" height={["400px", "390px", "500px"]} data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <chakra.form onSubmit={handleSignup} spellCheck={false} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            <Flex direction="column" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                                <HStack pb={6} marginBottom={[0, "20px"]} marginRight={["-55%", "-44%"]} data-sentry-element="HStack" data-sentry-source-file="index.tsx">
                                    <Heading fontSize={["35px", "50px", "70px"]} letterSpacing={[0, 2]} fontWeight="600" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
                                        livelink.vip/
                                    </Heading>
                                    <Input id="home-hero-username-input" ref={joinInputRef} placeholder="yourname" background="none" fontSize={["35px", "50px", "70px"]} fontWeight="600" width={["35%", "40%", "35%"]} height="80px" letterSpacing={[0, 0.2]} border={0} paddingX={["0px", 2]} value={username} onChange={e => setUsername(e.currentTarget.value?.trim())} _placeholder={{
                  color: "#9A9A9A"
                }} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
                                </HStack>
                                <Button id="home-hero-username-submit" type="submit" size="lg" variant="primary" fontSize="18px" padding="30px" marginBottom="50px" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                    Check availability
                                </Button>
                                <TimeSavedPill text="Total time saved - 100 hours p/m" data-sentry-element="TimeSavedPill" data-sentry-source-file="index.tsx" />
                            </Flex>
                        </chakra.form>
                    </Container>
                </Box>
            </Flex>
        </>;
};
export const fetchDataRequirements = async ({
  currentUser,
  req
}: IGetServerSidePropsContext) => {
  const xForwardedForHeader = req.headers["x-forwarded-for"];
  if (!currentUser && _c.isProduction && !_c.BASE_URL.includes("prod.preview")) {
    return {
      redirect: {
        destination: "https://livelink.ai",
        permanent: false
      }
    };
  }
  if (_c.isDev || _c.BASE_URL.includes("prod.preview")) {
    return {
      redirect: {
        destination: "/clips",
        permanent: false
      }
    };
  }
  return {
    props: {
      currentUser,
      appShell: currentUser ? {
        navbarOptions: {
          currentUser,
          transparent: "none"
        }
      } : {
        navbarOptions: {
          transparent: "start"
        },
        backgroundColor: "#ffffff"
      }
    }
  };
};
export const getServerSideProps = withAppContext(fetchDataRequirements);
export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP
})(IndexPage);