import { Box, Flex, HStack, Text, Textarea, TextareaProps } from "@chakra-ui/react";
import React from "react";
import { BaseInputGroup } from "./BaseInputGroup";
import { InputHeader } from "./InputHeader";
interface TextAreaProps extends TextareaProps {
  title?: string;
  inputProps: TextareaProps;
  label?: string;
  alwaysShowPlaceholder?: boolean;
  errorMessage?: string;
}
export const TextArea = (props: TextAreaProps) => {
  const hasTitle = !!props.title || !!props.inputProps.placeholder;
  const title = props.title ?? props.inputProps.placeholder ?? "";
  return <Box w="100%" data-sentry-element="Box" data-sentry-component="TextArea" data-sentry-source-file="TextArea.tsx">
            <BaseInputGroup isDisabled={props.inputProps.isDisabled} data-sentry-element="BaseInputGroup" data-sentry-source-file="TextArea.tsx">
                <Flex pos="absolute" top={0} left={0} paddingX={4} paddingY={1} width="100%" zIndex={3} justifyContent="space-between" alignItems="flex-end" pointerEvents="none" backgroundColor={props.inputProps.isDisabled ? "#E2E2E2" : "white"} transition="background 0.3s ease 0.1s" _groupHover={{
        backgroundColor: props.inputProps.isDisabled ? "#E2E2E2" : "#F7F7F7"
      }} _groupFocusWithin={{
        backgroundColor: "white"
      }} borderRadius="7px 7px 0 0" data-sentry-element="Flex" data-sentry-source-file="TextArea.tsx">
                    {hasTitle ? <div>
                            <InputHeader title={title} isVisible={props.title !== undefined ?? !!props.inputProps.value} />
                        </div> : <div />}
                    {props.inputProps.maxLength ? <Text fontSize={14} color="blackAlpha.600">{`${props.inputProps.value && typeof props.inputProps.value === "string" ? props.inputProps.value.length : 0}/${props.inputProps.maxLength}`}</Text> : null}
                </Flex>
                <Textarea {...props.inputProps} border="none" px={4} pt={props.title !== undefined || props.inputProps.value ? 8 : 2} pb={props.title !== undefined || props.inputProps.value ? 6 : 7} focusBorderColor="none" fontSize="16px" _placeholder={{
        color: "blackAlpha.800"
      }} data-sentry-element="Textarea" data-sentry-source-file="TextArea.tsx" />
            </BaseInputGroup>
            {props.label ? <Text fontSize={12} mt={2} color="blackAlpha.600">
                    {props.label}
                </Text> : null}
            {props.errorMessage ? <Text fontSize={12} mt={2} color="red">
                    {props.errorMessage}
                </Text> : null}
        </Box>;
};