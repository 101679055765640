import { Button, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdOutlineArrowForward } from "react-icons/md";
import api from "../../services/root_service";
import { getPageDebugInfo } from "../../utils/error_util";
import { TextArea } from "../TextArea";
import ErrorPageTemplate from "./ErrorPageTemplate";
const IGNORED_ERRORS = ["Failed to execute 'removeChild'", "WeakRef is not defined", "removeChild@"];
const DefaultErrorComponent = (props: {
  error: Error;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [hasSubmitted, setSubmitted] = useState(false);
  const [description, setDescription] = useState("");
  const handleReportProblemClick = async () => {
    try {
      setLoading(true);
      const shouldIgnoreError = IGNORED_ERRORS.some(ignoredError => (props.error.stack ?? "").includes(ignoredError));
      if (!shouldIgnoreError) {
        const debugInfo = await getPageDebugInfo();
        await api().postReportProblem({
          ...debugInfo,
          errorStack: props.error.stack,
          description
        });
      }
      setLoading(false);
      setSubmitted(true);
    } catch (error) {}
  };
  const renderBeforeSubmission = () => {
    return <>
                <Heading fontSize="lg" fontWeight="semibold" data-sentry-element="Heading" data-sentry-source-file="DefaultErrorPage.tsx">
                    Something went wrong
                </Heading>
                <Text fontSize="lg" data-sentry-element="Text" data-sentry-source-file="DefaultErrorPage.tsx">
                    {"We're sorry you've encountered this issue. Please let us know what happened so that our tech team can investigate it."}
                </Text>
                <Text fontSize="lg" data-sentry-element="Text" data-sentry-source-file="DefaultErrorPage.tsx">We appreciate your help in reporting it to us.</Text>
                <TextArea title="What happened?" inputProps={{
        id: "report",
        name: "report",
        height: 100,
        resize: "none",
        placeholder: "(Optional)",
        value: description,
        onChange: e => setDescription(e.target.value)
      }} data-sentry-element="TextArea" data-sentry-source-file="DefaultErrorPage.tsx" />
                <Button onClick={handleReportProblemClick} isLoading={isLoading} loadingText="Submitting..." variant="primary" size="lg" rightIcon={<Icon as={MdOutlineArrowForward} fontSize="20px" color="white" />} data-sentry-element="Button" data-sentry-source-file="DefaultErrorPage.tsx">
                    <Text as="span" color="white" data-sentry-element="Text" data-sentry-source-file="DefaultErrorPage.tsx">
                        Report problem
                    </Text>
                </Button>
            </>;
  };
  const renderAfterSubmission = () => {
    return <>
                <Heading fontSize="lg" fontWeight="semibold" data-sentry-element="Heading" data-sentry-source-file="DefaultErrorPage.tsx">
                    Thanks for reporting your problem
                </Heading>
                <Text fontSize="lg" data-sentry-element="Text" data-sentry-source-file="DefaultErrorPage.tsx">
                    Your problem has been reported to us and our tech team will begin investigating
                    the issue immediately.
                </Text>
                <Text fontSize="lg" data-sentry-element="Text" data-sentry-source-file="DefaultErrorPage.tsx">We may reach out to you for more information if needed.</Text>
                <Button as="a" href="/" variant="whiteWithDarkOutline" size="lg" rightIcon={<Icon as={MdOutlineArrowForward} fontSize="20px" color="black" />} data-sentry-element="Button" data-sentry-source-file="DefaultErrorPage.tsx">
                    Back to home
                </Button>
            </>;
  };
  return <ErrorPageTemplate data-sentry-element="ErrorPageTemplate" data-sentry-component="DefaultErrorComponent" data-sentry-source-file="DefaultErrorPage.tsx">
            <VStack display="flex" flexDirection="column" justifyContent="center" flex={1} maxWidth="340px" textAlign="center" marginTop="-100px" spacing="15px" data-sentry-element="VStack" data-sentry-source-file="DefaultErrorPage.tsx">
                {!hasSubmitted && renderBeforeSubmission()}
                {hasSubmitted && renderAfterSubmission()}
            </VStack>
        </ErrorPageTemplate>;
};
export default DefaultErrorComponent;