import { ArrowBackIcon } from "@chakra-ui/icons";
import { BoxProps, Modal as ChakraModal, HStack, IconButton, ModalCloseButton, ModalContent, ModalOverlay, ModalOverlayProps, ModalProps } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { FC, ReactNode, useEffect, useState } from "react";

// --------------------------------------------------------
// Types
// --------------------------------------------------------

export interface IModalProps {
  isOpen: boolean;
  children: ReactNode | ReactNode[];
  overlayStyles?: ModalOverlayProps;
  containerStyles?: BoxProps;
  contentContainerStyles?: BoxProps;
  onDismiss: () => void;
  canDismiss?: boolean;
  blockScrollOnMount?: boolean;
  accessibility?: {
    role?: string;
    "aria-labelledby"?: string;
    "aria-describedby"?: string;
  };
  hideDismissBtn?: boolean;
  showBackBtn?: boolean;
  onClickBack?: () => void;
  size?: ModalProps["size"];
}
export const Modal: FC<IModalProps> = ({
  isOpen,
  children,
  onDismiss,
  onClickBack,
  canDismiss = false,
  hideDismissBtn,
  showBackBtn,
  overlayStyles = {},
  contentContainerStyles = {},
  blockScrollOnMount = false,
  accessibility,
  size = "md"
}) => {
  const router = useRouter();
  const [isVisible, setVisibility] = useState<boolean>(false);
  useEffect(() => {
    const isErrorPage = router.pathname === "404" || router.pathname === "500";
    if (!isVisible && isOpen && !isErrorPage) {
      setVisibility(true);
    } else if (isVisible && !isOpen) {
      setVisibility(false);
    } else if (isErrorPage) {
      setVisibility(false);
    }
  }, [isVisible, isOpen, router.pathname]);
  if (!isOpen) {
    return null;
  }
  const extraAttributes = {
    ...accessibility
  };
  return <ChakraModal onClose={onDismiss!} isOpen={isOpen} blockScrollOnMount={blockScrollOnMount} closeOnOverlayClick={canDismiss} size={size} isCentered data-sentry-element="ChakraModal" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
            <ModalOverlay {...overlayStyles} data-sentry-element="ModalOverlay" data-sentry-source-file="Modal.tsx" />
            <ModalContent borderRadius="2xl" bgColor="white" {...contentContainerStyles} {...extraAttributes} data-sentry-element="ModalContent" data-sentry-source-file="Modal.tsx">
                <HStack data-sentry-element="HStack" data-sentry-source-file="Modal.tsx">
                    {showBackBtn && <IconButton onClick={onClickBack} _hover={{
          cursor: "pointer"
        }} background="white" aria-label="go back" margin="8px" boxSize="28px" padding="5px" as={ArrowBackIcon} boxShadow="lg" isRound />}
                    {canDismiss && !hideDismissBtn && <ModalCloseButton borderRadius="3xl" background="#ffffffc2" boxShadow="lg" zIndex={2} color="gray.700" />}
                </HStack>
                {children}
            </ModalContent>
        </ChakraModal>;
};
export default Modal;