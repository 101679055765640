import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
declare global {
  interface WindowEventMap {
    "local-storage": CustomEvent;
  }
}
type SetValue<T> = Dispatch<SetStateAction<T>>;
function useLocalStorage<T>({
  key,
  fallback
}: {
  key: string;
  fallback: T;
}): [T, SetValue<T>] {
  const readValue = useCallback((): T => {
    if (typeof window === "undefined") {
      return fallback;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item as T ?? fallback;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return fallback;
    }
  }, [fallback, key]);
  const [storedValue, setStoredValue] = useState<T>(readValue);
  const setValue: SetValue<T> = useCallback(value => {
    if (typeof window === "undefined") {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, newValue as any);
      setStoredValue(newValue);
      window.dispatchEvent(new Event("local-storage"));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  }, [key]);
  useEffect(() => {
    setStoredValue(readValue());
  }, []);
  const handleStorageChange = useCallback((event: StorageEvent | CustomEvent) => {
    if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
      return;
    }
    setStoredValue(readValue());
  }, [key, readValue]);
  useEffect(() => {
    window.addEventListener("local-storage", handleStorageChange);
  }, []);
  return [storedValue, setValue];
}
export default useLocalStorage;