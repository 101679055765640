import { InputGroup as ChakraInputGroup, InputGroupProps as ChakraInputGroupProps } from "@chakra-ui/react";
import React from "react";
export interface InputGroupProps extends ChakraInputGroupProps {
  children: React.ReactNode;
  width?: number | string;
  height?: number | string;
  isDisabled?: boolean;
}
export const BaseInputGroup = ({
  children,
  width,
  isDisabled,
  ...rest
}: InputGroupProps) => {
  return <ChakraInputGroup role="group" borderWidth={2} borderColor="black" borderRadius="7px" pos="relative" alignItems="center" backgroundColor={isDisabled ? "#E2E2E2" : "white"} width={width} overflow="hidden" transition="background 0.3s ease" _hover={{
    backgroundColor: isDisabled ? "#E2E2E2" : "#F7F7F7"
  }} _focusWithin={{
    backgroundColor: isDisabled ? "#E2E2E2" : "white"
  }} sx={{
    "--default-input-start-padding": "14px"
  }} {...rest} data-sentry-element="ChakraInputGroup" data-sentry-component="BaseInputGroup" data-sentry-source-file="BaseInputGroup.tsx">
            {children}
        </ChakraInputGroup>;
};