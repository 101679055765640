import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from "@chakra-ui/react";
import { useCallback } from "react";
interface IOptionedSliderProps {
  options: number[];
  value: number;
  onChange: (value: number) => void;
}
const OptionedSlider: React.FC<IOptionedSliderProps> = ({
  options,
  value,
  onChange
}) => {
  const isSingleOption = options.length === 1;
  const findClosestOptionIndex = useCallback((val: number) => {
    if (isSingleOption) return 0;
    return options.reduce((prevIndex, curr, currIndex) => Math.abs(curr - val) < Math.abs(options[prevIndex] - val) ? currIndex : prevIndex, 0);
  }, [options, isSingleOption]);
  const handleChange = (newValue: number) => {
    if (isSingleOption) {
      onChange(options[0]);
      return;
    }
    const closestIndex = findClosestOptionIndex(newValue);
    const closestValue = options[closestIndex];
    onChange(closestValue);
  };
  const VISUAL_START = 0.1;
  const VISUAL_END = 1.0;

  // Calculate the visual position for a given option index
  const getVisualPosition = (index: number) => {
    if (isSingleOption) return 0.1; // Pin to visual start for single option
    const visualRange = VISUAL_END - VISUAL_START;
    return VISUAL_START + index / (options.length - 1) * visualRange;
  };

  // Convert the current value to a visual position
  const valueToPosition = (val: number) => {
    if (isSingleOption) return getVisualPosition(0);
    const index = options.indexOf(val);
    return getVisualPosition(index);
  };
  return <Slider value={valueToPosition(value) * 100} min={0} max={100} step={0.1} onChange={newPosition => {
    if (isSingleOption) {
      onChange(options[0]);
      return;
    }
    const index = Math.round((newPosition / 100 - VISUAL_START) / ((1 - VISUAL_START) / (options.length - 1)));
    const optionIndex = Math.max(0, Math.min(index, options.length - 1));
    handleChange(options[optionIndex]);
  }} isDisabled={isSingleOption} data-sentry-element="Slider" data-sentry-component="OptionedSlider" data-sentry-source-file="OptionedSlider.tsx">
            <SliderTrack bg="gray.200" data-sentry-element="SliderTrack" data-sentry-source-file="OptionedSlider.tsx">
                <SliderFilledTrack bg="#06D6A0" data-sentry-element="SliderFilledTrack" data-sentry-source-file="OptionedSlider.tsx" />
            </SliderTrack>
            <SliderThumb boxSize={6} boxShadow="var(--chakra-shadows-base)" _focus={{
      boxShadow: "var(--chakra-shadows-base) !important"
    }} data-sentry-element="SliderThumb" data-sentry-source-file="OptionedSlider.tsx" />
        </Slider>;
};
export default OptionedSlider;