import { useCheckbox, UseCheckboxProps } from "@chakra-ui/checkbox";
import { chakra, forwardRef, HTMLChakraProps, omitThemingProps, SystemProps, SystemStyleObject, ThemingProps, useMultiStyleConfig } from "@chakra-ui/system";
import { __DEV__, cx, dataAttr } from "@chakra-ui/utils";
import * as React from "react";
export interface SwitchProps extends Omit<UseCheckboxProps, "isIndeterminate">, Omit<HTMLChakraProps<"label">, keyof UseCheckboxProps>, ThemingProps<"Switch"> {
  /**
   * The spacing between the switch and its label text
   * @default 0.5rem
   * @type SystemProps["marginLeft"]
   */
  spacing?: SystemProps["marginLeft"];
}
const SwitchInputOverride = forwardRef<SwitchProps, "input">((props, ref) => {
  const styles = useMultiStyleConfig("Switch", props);
  const {
    spacing = "0.5rem",
    children,
    ...ownProps
  } = omitThemingProps(props);
  const {
    state,
    getInputProps,
    getCheckboxProps,
    getRootProps,
    getLabelProps
  } = useCheckbox(ownProps);
  const containerStyles: SystemStyleObject = React.useMemo(() => ({
    display: "inline-block",
    position: "relative",
    verticalAlign: "middle",
    lineHeight: 0,
    ...styles.container
  }), [styles.container]);
  const trackStyles: SystemStyleObject = React.useMemo(() => ({
    display: "inline-flex",
    flexShrink: 0,
    justifyContent: "flex-start",
    boxSizing: "content-box",
    cursor: "pointer",
    ...styles.track,
    backgroundColor: "#9D9D9D",
    transition: "background-color 1s ease"
  }), [styles.track, props.isChecked]);
  const labelStyles: SystemStyleObject = React.useMemo(() => ({
    userSelect: "none",
    marginStart: spacing,
    ...styles.label
  }), [spacing, styles.label]);
  return <chakra.label {...getRootProps()} className={cx("chakra-switch", props.className)} __css={{
    ...containerStyles,
    "--switch-track-width": "44px",
    "--switch-track-height": "20px"
  }} display="inline-flex" alignItems="center">
            <input className="chakra-switch__input" {...getInputProps({}, ref)} />
            <chakra.span {...getCheckboxProps()} className="chakra-switch__track" __css={trackStyles} _checked={{
      backgroundColor: "#09D6A0"
    }}>
                <chakra.span __css={styles.thumb} className="chakra-switch__thumb" data-checked={dataAttr(state.isChecked)} data-hover={dataAttr(state.isHovered)} />
            </chakra.span>
            {children && <chakra.span className="chakra-switch__label" {...getLabelProps()} __css={labelStyles} fontWeight="500" marginInlineStart="10px" fontSize="14px">
                    {children}
                </chakra.span>}
        </chakra.label>;
});
export const SwitchInput = (props: any) => {
  const {
    children,
    ...rest
  } = props;
  return <SwitchInputOverride {...rest} data-sentry-element="SwitchInputOverride" data-sentry-component="SwitchInput" data-sentry-source-file="SwitchInput.tsx">
            {children ?? null}
        </SwitchInputOverride>;
};