import Script from "next/script";
import _c from "../configs/constants";
const GoogleTagManager = () => <>
        <Script id="google-tag-manager" strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="GoogleTagManager.tsx">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            `}
        </Script>
        <noscript>
            <iframe src={`https://www.googletagmanager.com/ns.html?id=GTM-${process.env.NEXT_PUBLIC_GTM_ID}`} height="0" width="0" style={{
      display: "none",
      visibility: "hidden"
    }} />
        </noscript>
    </>;
export default GoogleTagManager;