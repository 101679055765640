import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { isServer } from "../utils/browser_util";
interface Size {
  width: number;
  height: number;
}
const DEFAULT_DEBOUNCE_IN_MS = 500;
const useWindowSize = (args: {
  listenResize?: boolean;
  debounceInMs?: number;
  fallback?: {
    width?: number;
    height?: number;
  };
} = {
  listenResize: true
}): Size => {
  const fallbackWidth = args?.fallback?.width ?? 0;
  const fallbackHeight = args?.fallback?.width ?? 0;
  const [windowSize, setWindowSize] = useState<Size>({
    width: isServer ? fallbackWidth : window.innerWidth,
    height: isServer ? fallbackHeight : window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    const debouncedHandleResize = debounce(handleResize, args.debounceInMs ?? DEFAULT_DEBOUNCE_IN_MS);
    if (args && args.listenResize) {
      window.addEventListener("resize", debouncedHandleResize);
    }
    handleResize();
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
      debouncedHandleResize.cancel();
    };
  }, [args?.listenResize, args?.debounceInMs]);
  return windowSize;
};
export default useWindowSize;