import { User } from "@prisma/client";
import withRouter, { WithRouterProps } from "next/dist/client/with-router";
import { useEffect, useRef } from "react";
import _c from "../../configs/constants";
import AttributionController from "../../controllers/attribution_controller";
import useLocalStorage from "../../hooks/useLocalStorage";
import type { PostUpdateUserAttributionBody } from "../../pages/api/v1/track/user/attribution";
import api from "../../services/root_service";
import { getCookie, unsetCookie } from "../../utils/cookie_util";
const sourceUrlMap = ["Google", "Instagram", "Facebook", "Youtube", "Tiktok", "Tumblr", "Bing", "Yahoo", "Twitter", "LiveLink"];
interface IScreenTrackingProps extends WithRouterProps {
  currentUser?: any;
  children?: React.ReactNode;
}
const ScreenTracking = (props: IScreenTrackingProps) => {
  const {
    router,
    currentUser
  } = props;
  const prevUserRef = useRef<User | undefined>();
  const [firstAttribution, setFirstAttribution] = useLocalStorage({
    key: _c.LOCAL_STORAGE_KEYS.FIRST_ATTRIBUTION_KEY,
    fallback: null
  });
  const [platSubsTests, setPlatSubsTests] = useLocalStorage({
    key: _c.PRODUCT_TESTS.PLATFORM_SUBSCRIPTIONS,
    fallback: false
  });
  const [snippetTest, setSnippetTest] = useLocalStorage({
    key: _c.PRODUCT_TESTS.SNIPPET_GENERATOR,
    fallback: false
  });
  const [gclId, setGclId] = useLocalStorage({
    key: _c.LOCAL_STORAGE_KEYS.GCLID,
    fallback: ""
  });
  const translateReferralSource = (referrer = "") => {
    if (!referrer) {
      return undefined;
    }
    const result = sourceUrlMap.find(source => {
      const s = source.toLowerCase();
      return referrer.search(`https?://(.*)${s}.([^/?]*)`) === 0;
    });
    return result ? result : `Other (${referrer})`;
  };
  const trackGoogleClickId = async () => {
    if (!currentUser) {
      return;
    }
    const gclIdFromCookies = getCookie(_c.COOKIE_STORAGE_KEYS.GCLID);
    if (gclIdFromCookies) {
      setGclId(gclIdFromCookies);
    }
    const gclId = localStorage.getItem(_c.LOCAL_STORAGE_KEYS.GCLID);
    if (gclId) {
      await api().trackUserAttribution({
        gclId
      });
      localStorage.removeItem(_c.LOCAL_STORAGE_KEYS.GCLID);
    }
  };
  const trackAttribution = async () => {
    if (!currentUser) {
      return;
    }
    const initialPage = getCookie(_c.COOKIE_STORAGE_KEYS.INITIAL_PAGE);
    const initialReferrer = getCookie(_c.COOKIE_STORAGE_KEYS.REFERRER);
    const updateValues = {} as PostUpdateUserAttributionBody;
    if (initialPage && initialPage !== _c.COOKIE_STORAGE_UNSET) {
      updateValues["initialPage"] = initialPage;
    }
    if (initialReferrer && initialReferrer !== _c.COOKIE_STORAGE_UNSET) {
      updateValues["referrer"] = initialReferrer;
    }
    if (Object.values(updateValues).length > 0) {
      await api().trackUserAttribution(updateValues);
      unsetCookie(_c.COOKIE_STORAGE_KEYS.INITIAL_PAGE);
      unsetCookie(_c.COOKIE_STORAGE_KEYS.REFERRER);
    }
  };
  const trackPage = async (url?: string) => {
    let previousPagePath: string;
    let path: string;
    if (url) {
      // react router action
      previousPagePath = props.router.asPath;
      path = url;
    } else {
      // hard link / referral
      path = props.router.asPath;
      previousPagePath = (document.referrer || "").replace(_c.BASE_URL as string, "");
    }
    import("../../controllers/analytics_controller").then(module => {
      const Analytics = module.default;
      Analytics.trackPage({
        path,
        previousPagePath,
        title: document.title,
        source: document.referrer ? translateReferralSource(document.referrer) : undefined
      });
    }).catch(console.error);
    await trackGoogleClickId();
    await trackAttribution();

    // If a user's first visit to the site is the pricing page, place them in the test
    if (router.pathname === "/pricing" || router.query.pt === _c.PRODUCT_TESTS.PLATFORM_SUBSCRIPTIONS) {
      if (!firstAttribution || router.query.ptm === _c.PRODUCT_TEST_METHODS.CALL) {
        setPlatSubsTests(true);
      }
    }
    if (router.pathname === "/clips" || router.query.pt === _c.PRODUCT_TESTS.SNIPPET_GENERATOR || router.pathname === "/pricing") {
      if (!firstAttribution && !snippetTest) {
        setSnippetTest(true);
      }
    }
    if (router.pathname === "/[creatorSlug]") {
      try {
        const url = location.href;
        const creatorSlug = (router.query as {
          creatorSlug: string;
        }).creatorSlug;
        const visitData = AttributionController.updateUserAttribution(creatorSlug);
        await api().trackProfileVisit({
          url,
          ...visitData
        });
      } catch (error) {
        if (_c.isDevOrStaging) {
          console.error(error);
          console.error("Unable to track page visit");
        }
      }
    }
  };
  useEffect(() => {
    prevUserRef.current = currentUser;
    import("../../controllers/analytics_controller").then(module => {
      const Analytics = module.default;
      if (!currentUser) {
        Analytics.identifyGuest();
      } else if (currentUser && currentUser.id) {
        Analytics.identifyUser(currentUser);
      }
    });
    trackPage();
    router.events.on("routeChangeStart", trackPage);
    return () => {
      router.events.off("routeChangeStart", trackPage);
    };
  }, []);
  useEffect(() => {
    if (!prevUserRef.current && currentUser && currentUser.id) {
      import("../../controllers/analytics_controller").then(module => {
        const Analytics = module.default;
        Analytics.identifyUser(currentUser);
      });
    }
  }, [currentUser]);
  return <>{props.children}</>;
};
export default withRouter(ScreenTracking) as any;