import * as Sentry from "@sentry/nextjs"
import { NextPageContext } from "next"

import { ErrorStackInfo } from "../../utils/error_util"

export class SentryClient {
    static async captureException(context: NextPageContext) {
        return Sentry.captureException(context)
    }

    static async captureComponentError(
        error: Error,
        stack: ErrorStackInfo,
        componentStack: string,
    ) {
        try {
            Sentry.withScope((scope) => {
                scope.setExtra("componentStack", componentStack)
                scope.setExtra("stackTrace", stack.stackString)
                scope.setExtra("fingerprint", stack.fingerprint)
                scope.setTag("errorType", error.name)
                scope.setTag("component", "ErrorBoundary")
                scope.setFingerprint([stack.fingerprint])
                Sentry.captureException(error)
            })

            return
        } catch (loggingError) {
            console.error("Error logging failed:", loggingError)
            Sentry.captureException(loggingError)
            return null
        }
    }
}
