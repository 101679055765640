import * as Sentry from "@sentry/nextjs";
import React, { ReactNode } from "react";
import * as StackTrace from "stacktrace-js";
import { AE } from "../../@types/analytics";
import _c from "../../configs/constants";
import { SentryClient } from "../../server/clients/sentry_client";
import { ErrorStackClient } from "../../utils/error_util";
import DefaultErrorComponent from "./DefaultErrorPage";
interface ErrorBoundaryProps {
  children: ReactNode;
}
interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: string | null;
}
const SHOW_IN_DEV = false;
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      error,
      errorInfo: null
    };
  }
  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo): Promise<void> {
    try {
      const errorStack = await ErrorStackClient.getErrorDetails(error);
      this.setState({
        errorInfo: errorStack.stackString,
        error
      });
      SentryClient.captureComponentError(error, errorStack, errorInfo.componentStack);
      const Analytics = (await import("../../controllers/analytics_controller")).default;
      Analytics.trackEvent(AE.Application_Error, {
        message: error?.message
      });
      if (_c.isDev) {
        console.group("React Error Boundary Caught Error:");
        console.error("Error:", error);
        console.error("Stack Trace:", errorStack.stackString);
        console.error("Component Stack:", errorInfo.componentStack);
        console.groupEnd();
      }
    } catch (loggingError) {
      console.error("Error logging failed:", loggingError);
      Sentry.captureException(loggingError);
    }
  }
  render() {
    if (!!this.state.error) {
      if (_c.isDev && !SHOW_IN_DEV) {
        return this.props.children;
      }
      return <DefaultErrorComponent error={this.state.error} data-sentry-element="DefaultErrorComponent" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx" />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;