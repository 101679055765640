import { Alert, AlertIcon, Box, Button, Flex, Image, Text, useToast, UseToastOptions } from "@chakra-ui/react";
import { Auth, getAuth, sendEmailVerification, User } from "firebase/auth";
import { NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";
import { AE } from "../@types/analytics";
import _c from "../configs/constants";
import Analytics from "../controllers/analytics_controller";
import { TFirebaseActionErrorStack } from "../hooks/useFirebaseAuthAction";
import { BaseUser } from "../server/dao/user_dao";
import api from "../services/root_service";
import { removeParamFromUrl } from "../utils/browser_util";
import { EAuthErrorCode, getAuthEmailErrorText } from "../utils/error_util";
import Navbar, { NAVBAR_HEIGHT } from "./Navbar";
const fetchFirebaseCurrentUser = (auth: Auth): Promise<User> => new Promise(resolve => {
  let attempt = 0;
  const intervalId = setInterval(() => {
    attempt++;
    if (auth.currentUser) {
      clearInterval(intervalId);
      resolve(auth.currentUser);
    }
    if (attempt > 10) {
      clearInterval(intervalId);
      throw new Error("Failed to fetch current user", {
        cause: EAuthErrorCode.FailedToFetchUser
      });
    }
  }, 3_00);
});
export const sendEmailVerificationToUser = async (userId: string, projectId?: string) => {
  try {
    const auth = getAuth();
    const currentUser = await fetchFirebaseCurrentUser(auth);
    if (!currentUser) {
      throw new Error("User is not logged in");
    }
    const response = await api().postPrepareEmailVerification({
      userId,
      projectId
    });
    if (!response.success) {
      let message = "";
      if (response.code === EAuthErrorCode.AlreadyRequested) {
        const minutes = Math.floor(response.duration / 60);
        const seconds = response.duration % 60;
        message = `Please wait ${seconds}s before sending another email verification.`;
        if (minutes > 0) {
          message = `Please wait ${minutes}m ${seconds}s before sending another email verification.`;
        }
        throw new Error(message, {
          cause: EAuthErrorCode.AlreadyRequested
        });
      } else if (response.code === EAuthErrorCode.AlreadyVerified) {
        message = "This email has already been verified.";
        throw new Error(message, {
          cause: EAuthErrorCode.AlreadyVerified
        });
      } else if (response.code === EAuthErrorCode.SkipVerification) {
        return;
      }
      message = getAuthEmailErrorText(EAuthErrorCode.UncaughtError).message;
      throw new Error(message);
    }
    return sendEmailVerification(currentUser, {
      url: response.url,
      handleCodeInApp: true
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};
export const EmailVerification = ({
  currentUser,
  backgroundColor
}: {
  currentUser: BaseUser;
  backgroundColor?: string;
}) => {
  const [isResendingEnabled, setIsResendingEnabled] = useState(true);
  const [error, setError] = useState<TFirebaseActionErrorStack | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const handleClickResend = async () => {
    setIsResendingEnabled(false);
    try {
      setError(null);
      setInfo(null);
      await sendEmailVerificationToUser(currentUser.id);
      setInfo(`We have sent you a new login link to ${currentUser.email}.`);
      Analytics.trackEvent(AE.Signup_ResendEmailVerification, {
        email: currentUser.email
      });
    } catch (err) {
      console.error(err);
      if (err.cause) {
        setError({
          type: err.cause,
          message: err.message
        });
      } else if (err.message.includes("auth/too-many-requests")) {
        setError({
          type: EAuthErrorCode.TooManyRequests,
          message: "You've attempted to resend the email too many times."
        });
      } else {
        setError({
          type: EAuthErrorCode.UncaughtError,
          message: "Failed to send email. Please try again."
        });
      }
    }
    setIsResendingEnabled(true);
  };
  const renderErrorMessage = () => {
    if (!error) {
      return null;
    }
    return <Alert status="error" data-sentry-element="Alert" data-sentry-component="renderErrorMessage" data-sentry-source-file="EmailVerification.tsx">
                <AlertIcon as="div" alignSelf="flex-start" marginRight="15px" data-sentry-element="AlertIcon" data-sentry-source-file="EmailVerification.tsx" />
                <Text as="span" data-sentry-element="Text" data-sentry-source-file="EmailVerification.tsx">{error.message}</Text>
            </Alert>;
  };
  const renderInfo = () => {
    if (!info) {
      return null;
    }
    return <Alert status="success" data-sentry-element="Alert" data-sentry-component="renderInfo" data-sentry-source-file="EmailVerification.tsx">
                <AlertIcon as="div" alignSelf="flex-start" marginRight="15px" data-sentry-element="AlertIcon" data-sentry-source-file="EmailVerification.tsx" />
                <Text as="span" data-sentry-element="Text" data-sentry-source-file="EmailVerification.tsx">{info}</Text>
            </Alert>;
  };
  return <Flex as="main" direction="column" height={`calc(100vh - ${NAVBAR_HEIGHT})`} minWidth="100vw" alignItems="center" gap="24px" justifyContent="center" backgroundColor={backgroundColor ?? "#F7F7F7"} data-sentry-element="Flex" data-sentry-component="EmailVerification" data-sentry-source-file="EmailVerification.tsx">
            <Flex direction="column" gap="8px" maxWidth="350px" justifyContent="center" alignItems="center" textAlign="center" data-sentry-element="Flex" data-sentry-source-file="EmailVerification.tsx">
                <Image src="/images/envelope.png" alt="envelope" width="32px" height="32px" data-sentry-element="Image" data-sentry-source-file="EmailVerification.tsx" />
                <Text fontWeight="bold" fontSize="20px" lineHeight="24px" marginBottom={0} data-sentry-element="Text" data-sentry-source-file="EmailVerification.tsx">
                    We have sent you a login link
                </Text>
                <Text fontSize="16px" lineHeight="24px" marginBottom={0} data-sentry-element="Text" data-sentry-source-file="EmailVerification.tsx">
                    Go the email account <strong>{currentUser.email}</strong> and click the link to
                    continue.
                </Text>
            </Flex>
            <Flex gap="4px" flexDirection="column" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="EmailVerification.tsx">
                <Flex gap="4px" data-sentry-element="Flex" data-sentry-source-file="EmailVerification.tsx">
                    <Text fontSize="16px" lineHeight="24px" marginBottom={0} data-sentry-element="Text" data-sentry-source-file="EmailVerification.tsx">
                        Not received the email?
                    </Text>
                    <Button variant="transparent" paddingLeft={0} onClick={handleClickResend} isDisabled={!isResendingEnabled} _disabled={{
          _hover: {
            opacity: 0.4
          },
          cursor: "not-allowed"
        }} data-sentry-element="Button" data-sentry-source-file="EmailVerification.tsx">
                        <Text fontWeight="bold" fontSize="16px" lineHeight="24px" textDecoration="underline" marginBottom="12px" data-sentry-element="Text" data-sentry-source-file="EmailVerification.tsx">
                            Resend
                        </Text>
                    </Button>
                </Flex>
                <Box height="47px" data-sentry-element="Box" data-sentry-source-file="EmailVerification.tsx">
                    {renderInfo()}
                    {renderErrorMessage()}
                </Box>
            </Flex>
        </Flex>;
};
interface IWithEmailVerificationProps {
  currentUser: BaseUser & {
    isFirebaseEmailVerified: boolean;
    isRefreshable: boolean;
  };
  appShell?: {
    backgroundColor?: string;
  };
}
export const withEmailVerification = <P extends IWithEmailVerificationProps,>(WrappedComponent: React.ComponentType<P>) => {
  const WithEmailVerification: React.FC<P> = props => {
    const router: NextRouter = useRouter();
    const showToast = useToast();
    const {
      currentUser,
      appShell
    } = props;
    useEffect(() => {
      if (router.query.errCode as string) {
        const authErrorState = getAuthEmailErrorText(router.query.errCode as EAuthErrorCode);
        removeParamFromUrl(router, "errCode", false);
        const description = [authErrorState.message];
        if (authErrorState.resendEnabled) {
          description.push("Please click Resend to get a new one.");
        }
        const toastOptions: UseToastOptions = {
          title: "Verification failed",
          status: "error",
          duration: 5000,
          isClosable: true
        };
        if (router.query.errCode === EAuthErrorCode.AlreadyVerified) {
          toastOptions.title = "Already verified";
          toastOptions.status = "success";
        }
        showToast({
          ...toastOptions,
          description: description.join(" ")
        });
      }
    }, [router.query]);
    if (!_c.isDevOrTbd && currentUser && currentUser.isEmailVerified !== null && !currentUser.isFirebaseEmailVerified) {
      return <>
                    <Navbar currentUser={currentUser} hideUserNavigation hidePills />
                    <EmailVerification currentUser={currentUser} backgroundColor={appShell?.backgroundColor} />
                </>;
    }
    return <WrappedComponent {...props} data-sentry-element="WrappedComponent" data-sentry-component="WithEmailVerification" data-sentry-source-file="EmailVerification.tsx" />;
  };
  return WithEmailVerification;
};