import { ChakraProvider } from "@chakra-ui/react";
import type { AppProps } from "next/app";
import type { NextPage } from "next/types";
import { ReactElement, ReactNode, useRef } from "react";
import AppShell from "../components/AppShell";
import Clarity from "../components/Clarity";
import Drip from "../components/Drip";
import GlobalModals from "../components/GlobalModals";
import GoogleTagManager from "../components/GoogleTagManager";
import ScreenTracking from "../components/HOC/ScreenTracking";
import HyrosScript from "../components/HyrosScript";
import { BaseMetaTags } from "../components/MetaTags";
import type { IPlatformSubscriptionModalRef } from "../components/subscriptions/PlatformSubscriptionModal";
import ErrorBoundary from "../components/utility/ErrorBoundary";
import _c from "../configs/constants";
import initApp from "../configs/init";
import liveLinkTheme from "../configs/theme";
import { CurrentUserContext, PlatformSubscriptionModalContext } from "../utils/context_util";
import "../styles/global.css";
import "../styles/libraries.css";
export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: any;
};
initApp();
function MyApp({
  Component,
  pageProps
}: AppPropsWithLayout) {
  const subModalRef = useRef<IPlatformSubscriptionModalRef | null>(null);
  const getLayout = Component.getLayout ?? ((page: any) => page);
  const currentUser = pageProps.currentUser ?? null;
  return <ChakraProvider theme={liveLinkTheme} resetCSS={true} data-sentry-element="ChakraProvider" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
            <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
                <BaseMetaTags data-sentry-element="BaseMetaTags" data-sentry-source-file="_app.tsx" />
                <ScreenTracking {...pageProps} data-sentry-element="ScreenTracking" data-sentry-source-file="_app.tsx">
                    <AppShell {...pageProps} data-sentry-element="AppShell" data-sentry-source-file="_app.tsx">
                        <CurrentUserContext.Provider value={currentUser} data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
                            {/* 
                             We show this modal on several pages and components,
                             to prevent large request overhead per page it is being moved here.
                             */}
                            <PlatformSubscriptionModalContext.Provider value={subModalRef} data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
                                <GlobalModals {...pageProps} data-sentry-element="GlobalModals" data-sentry-source-file="_app.tsx" />
                                {getLayout(<Component {...pageProps} />)}
                            </PlatformSubscriptionModalContext.Provider>
                        </CurrentUserContext.Provider>
                    </AppShell>
                </ScreenTracking>
                {_c.isProductionOrStaging && <Clarity />}
                {_c.isProduction && <Drip />}
                {_c.isProduction && <GoogleTagManager />}
                {_c.isProduction && <HyrosScript />}
            </ErrorBoundary>
        </ChakraProvider>;
}
export default MyApp;