import { Box, Container, Image } from "@chakra-ui/react";
import NextLink from "next/link";
import React, { ReactNode } from "react";
const ErrorPageTemplate = (props: {
  children: ReactNode;
}) => <Container width="100vw" height="100vh" display="flex" flexDirection="column" alignItems="center" data-sentry-element="Container" data-sentry-component="ErrorPageTemplate" data-sentry-source-file="ErrorPageTemplate.tsx">
        <NextLink href="/" passHref legacyBehavior data-sentry-element="NextLink" data-sentry-source-file="ErrorPageTemplate.tsx">
            <Box as="a" padding={6} data-sentry-element="Box" data-sentry-source-file="ErrorPageTemplate.tsx">
                <Image src="/images/livelink_logo.svg" alt="LiveLink Logo" width="28px" data-sentry-element="Image" data-sentry-source-file="ErrorPageTemplate.tsx" />
            </Box>
        </NextLink>
        {props.children}
    </Container>;
export default ErrorPageTemplate;