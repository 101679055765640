import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { MdLoop } from "react-icons/md";
const getTagLabelText = (category: TTagLabelCategory) => {
  if (category === "happening-now") {
    return "Happening now";
  } else if (category === "full") {
    return "Live is full";
  } else if (category === "spaces-left") {
    return "Last few spaces";
  } else if (category === "ending-soon") {
    return "Starting soon";
  } else if (category === "popular") {
    return "Popular";
  } else if (category === "free-for-subscribers") {
    return "Free for Subscribers";
  } else if (category === "ready") {
    return "Ready";
  } else if (category === "expired") {
    return "Expired";
  } else if (category === "repeating-daily") {
    return "Daily Live";
  } else if (category === "repeating-weekly") {
    return "Weekly Live";
  } else if (category === "repeating-biweekly") {
    return "Biweekly Live";
  } else if (category === "repeating-monthly") {
    return "Monthly Live";
  } else if (category === "new") {
    return "New";
  }
  return null;
};
export type TTagLabelCategory = "generic" | "not-published" | "creator-sold-out" | "creator-some-sales" | "creator-no-sales" | "popular" | "happening-now" | "spaces-left" | "full" | "ending-soon" | "free-for-subscribers" | "ready" | "expired" | "repeating-daily" | "repeating-weekly" | "repeating-biweekly" | "repeating-monthly" | "course-live-preview-tier-1" | "course-live-preview-tier-2" | "new";
interface TagLabelProps {
  category: TTagLabelCategory;
  options?: {
    liveCompleted?: boolean;
    totalEarnable?: string;
    totalEarned?: string;
    attendeeCount?: number;
    spacesRemaining?: number;
    tierName?: string;
    customText?: string;
  };
}
export const TagLabel = (props: TagLabelProps) => {
  const isRepeating = useMemo(() => {
    return !!props.category ? props.category.includes("repeating") : false;
  }, [props.category]);
  const backgroundColor = useMemo(() => {
    if (props.category === "happening-now") {
      return "green.500";
    }
    if (isRepeating) {
      return "#81BFFA";
    }
    return "white";
  }, [props.category]);
  if (props.category === "generic") {
    return <Box backgroundColor="#000000" color="white" border="1px solid white" borderRadius="9px" textTransform="uppercase" fontWeight="semibold" fontSize="xs" padding="5px 12px" zIndex={1}>
                {props.options?.customText ?? ""}
            </Box>;
  }
  if (props.category === "not-published") {
    return <Box backgroundColor="#000000" color="white" border="1px solid white" borderRadius="9px" textTransform="uppercase" fontWeight="semibold" fontSize="xs" padding="5px 12px" zIndex={1}>
                Not Published
            </Box>;
  }
  if (props.category === "new") {
    return <Box backgroundColor="green.500" color="white" borderRadius="9px" textTransform="uppercase" fontWeight="semibold" fontSize="xs" padding="3px 11px" zIndex={1} ml={2}>
                New
            </Box>;
  }
  if (props.category === "course-live-preview-tier-2") {
    return <Box background="linear-gradient(to right bottom, #D3CA6A, #F2E1B1, #CAAA4D)" color="black" border="1px solid black" borderRadius="9px" textTransform="uppercase" fontWeight="semibold" fontSize="xs" padding="5px 12px" zIndex={1}>
                {props.options?.tierName}
            </Box>;
  }
  if (props.category === "course-live-preview-tier-1") {
    return <Box backgroundColor="white" color="black" border="1px solid black" borderRadius="9px" textTransform="uppercase" fontWeight="semibold" fontSize="xs" padding="5px 12px" zIndex={1}>
                {props.options?.tierName}
            </Box>;
  }
  const renderContents = () => {
    if (props.category === "creator-no-sales") {
      return <HStack spacing={1}>
                    <Text color="#09D6A0">{props.options!.totalEarnable}</Text>
                    <Text>POTENTIAL EARNINGS</Text>
                </HStack>;
    }
    if (props.category === "creator-sold-out") {
      const {
        totalEarned,
        attendeeCount
      } = props.options!;
      return <Text textTransform="uppercase">{`${attendeeCount} ATTENDEES · ${totalEarned} EARNED`}</Text>;
    }
    if (props.category === "creator-some-sales") {
      const {
        liveCompleted,
        spacesRemaining,
        attendeeCount
      } = props.options!;
      return <HStack spacing={1}>
                    {!liveCompleted && <>
                            <HStack spacing={1}>
                                <Text color="#DE6700">{spacesRemaining}</Text>
                                <Text>{spacesRemaining === 1 ? "SPACE LEFT" : "SPACES LEFT"}</Text>
                            </HStack>
                            <Text>&middot;</Text>
                        </>}
                    <HStack spacing={1}>
                        <Text>{attendeeCount}</Text>
                        <Text>{attendeeCount === 1 ? "ATTENDEE" : "ATTENDEES"}</Text>
                    </HStack>
                </HStack>;
    }
    if (isRepeating) {
      return <HStack spacing={2}>
                    <Icon as={MdLoop} boxSize={6} color="white" />
                    <Text color={"#141414"} fontSize="13px" textTransform="uppercase">
                        {getTagLabelText(props.category)}
                    </Text>
                </HStack>;
    }
    return <HStack spacing={2} data-sentry-element="HStack" data-sentry-component="renderContents" data-sentry-source-file="TagLabel.tsx">
                <Text color={"#212121"} textTransform="uppercase" data-sentry-element="Text" data-sentry-source-file="TagLabel.tsx">
                    {getTagLabelText(props.category)}
                </Text>
            </HStack>;
  };
  return <Box backgroundColor={backgroundColor} flexBasis="auto" color="#212121" border="2px solid black" borderRadius="11px" fontSize="sm" fontWeight="semibold" padding="4px 12px" zIndex={1} _empty={{
    display: "none"
  }} data-sentry-element="Box" data-sentry-component="TagLabel" data-sentry-source-file="TagLabel.tsx">
            {renderContents()}
        </Box>;
};