import { Box, InputProps, SlideFade, Text } from "@chakra-ui/react";
import React from "react";
interface InputHeaderProps extends InputProps {
  title: string;
  isVisible: boolean;
  left?: string;
}
export const InputHeader = ({
  title,
  isVisible,
  left,
  ...rest
}: InputHeaderProps) => {
  return (
    // @ts-ignore 
    <Box pos="absolute" top={2} left={left ?? "var(--default-input-start-padding)"} zIndex={99} pointerEvents="none" {...rest} data-sentry-element="Box" data-sentry-component="InputHeader" data-sentry-source-file="InputHeader.tsx">
            <SlideFade in={isVisible} data-sentry-element="SlideFade" data-sentry-source-file="InputHeader.tsx">
                <Text fontSize={10} color="blackAlpha.600" data-sentry-element="Text" data-sentry-source-file="InputHeader.tsx">
                    {title}
                </Text>
            </SlideFade>
        </Box>
  );
};