import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";
const useIsMobile = (config: {
  listenResize?: boolean;
} = {
  listenResize: true
}) => {
  const [renderForMobile, setRenderForMobile] = useState<boolean>(false);
  const {
    width
  } = useWindowSize({
    listenResize: config.listenResize
  });
  const isWidthMobile = !!(width && width < 768);
  useEffect(() => {
    setRenderForMobile(isWidthMobile);
  }, [isWidthMobile, renderForMobile]);
  return renderForMobile;
};
export default useIsMobile;