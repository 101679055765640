import { Box, Container, Flex, HStack, Icon, Image, Link, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import _c from "../configs/constants";
import SiteLink from "./SiteLink";

// TODO: make footer opt-out rather than opt-in
const ALLOWED_PAGES = ["/", "/live/create", "/terms", "/privacy", "/cookies", "/live/create", "/creators"];
const BLOG_LINK = "https://livelink.notion.site/fd6b1fb6362e42939e30073569934ed0?v=d6948dbe57e04641a2832243d3a3c3f0";
const Footer = () => {
  const router = useRouter();
  const showFooter = ALLOWED_PAGES.includes(router.asPath.split("?")[0]) || router.pathname === "/[creatorSlug]" || router.pathname === "/live/[slug]/edit";
  if (!showFooter) {
    return null;
  }
  return <Container as="footer" maxW="100vw" borderTop="1px solid" borderTopColor="blackAlpha.200" data-sentry-element="Container" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
            <Container maxW="container.xl" px={[4, 6, 8]} data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
                <Flex direction="column" py={[4, null, 12]} data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
                    <Flex direction={["column", null, "row"]} mb={12} data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
                        <VStack spacing={4} alignItems="flex-start" marginBottom={4} data-sentry-element="VStack" data-sentry-source-file="Footer.tsx">
                            <NextLink href="/" passHref legacyBehavior data-sentry-element="NextLink" data-sentry-source-file="Footer.tsx">
                                <Box as="a" data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
                                    <Image src="/images/livelink_logo_text.svg" alt="LiveLink Logo" width="90px" height="auto" objectFit="contain" mx={2} my={2} data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
                                </Box>
                            </NextLink>
                            <Link href="https://www.tiktok.com/@livelinkvip" target="_blank" display="inline-flex" alignItems="center" pl={2} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                                <Icon as={FaTiktok} marginRight={2} data-sentry-element="Icon" data-sentry-source-file="Footer.tsx" /> Tiktok
                            </Link>
                            <Link href="https://www.instagram.com/livelinkvip" target="_blank" display="inline-flex" alignItems="center" pl={2} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                                <Icon as={FaInstagram} marginRight={2} data-sentry-element="Icon" data-sentry-source-file="Footer.tsx" /> Instagram
                            </Link>
                        </VStack>
                        <Flex direction={["column", null, "row"]} justifyContent={["flex-start", null, "flex-end"]} flex={1} data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
                            <VStack spacing={4} alignItems="flex-start" direction="column" pl={[8, null, 6]} marginRight={[0, 0, 12]} marginBottom={[4, null, 0]} data-sentry-element="VStack" data-sentry-source-file="Footer.tsx">
                                <Link href={BLOG_LINK} target="_black" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                                    Blog
                                </Link>
                                <SiteLink href="/signup" data-sentry-element="SiteLink" data-sentry-source-file="Footer.tsx">Become a creator</SiteLink>
                            </VStack>
                            <VStack spacing={4} alignItems="flex-start" direction="column" pl={[8, null, 6]} data-sentry-element="VStack" data-sentry-source-file="Footer.tsx">
                                <SiteLink href="/privacy" data-sentry-element="SiteLink" data-sentry-source-file="Footer.tsx">Privacy policy</SiteLink>
                                <SiteLink href="/cookies" data-sentry-element="SiteLink" data-sentry-source-file="Footer.tsx">Cookie policy</SiteLink>
                                <SiteLink href="/terms" data-sentry-element="SiteLink" data-sentry-source-file="Footer.tsx">Terms and conditions</SiteLink>
                            </VStack>
                        </Flex>
                    </Flex>
                    <Flex direction="row" justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="Footer.tsx">
                        <Text fontSize="xs" color="blackAlpha.500" px={2} py={2} data-sentry-element="Text" data-sentry-source-file="Footer.tsx">
                            {`Copyright ${dayjs().format("YYYY")}. All rights reserved.`}
                            <br />
                            {"LiveLink is a property of Scoodle Ltd."}
                        </Text>
                        {_c.isAWS && <Text fontSize="xs" color="blackAlpha.500" px={2} py={2}>
                                {`AWS`}
                                {_c.VERSION ? ` · ${_c.VERSION}` : ""}
                                <br />
                                {_c.AWS_ENV}
                            </Text>}
                    </Flex>
                </Flex>
            </Container>
        </Container>;
};
export default Footer;